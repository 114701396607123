import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export const useSmartphoneBreakpoint = () => {
  const theme = useTheme();
  const matches_smartphone_breakpoint = useMediaQuery(
    theme.breakpoints.down("sm")
  );
  // console.log("Smartphone breakpoint: ", matches_smartphone_breakpoint);

  return matches_smartphone_breakpoint;
};

export const useTabletBreakpoint = () => {
  const theme = useTheme();
  const matches_sm_up = useMediaQuery(theme.breakpoints.up("sm"));
  const matches_lg_down = useMediaQuery(theme.breakpoints.down("md"));
  const matches_tablet_breakpoint = matches_sm_up && matches_lg_down;
  // console.log("Tablet breakpoint: ", matches_tablet_breakpoint);

  return matches_tablet_breakpoint;
};

export const useDesktopBreakpoint = () => {
  const theme = useTheme();
  const matches_desktop_breakpoint = useMediaQuery(theme.breakpoints.up("md"));
  // console.log("Desktop breakpoint: ", matches_desktop_breakpoint);

  return matches_desktop_breakpoint;
};

// const smartphone = useSmartphoneBreakpoint();
// const tablet = useTabletBreakpoint();
// const desktop = useDesktopBreakpoint();

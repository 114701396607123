import { Typography, Box, Link } from "@mui/material";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";

import {
  useSmartphoneBreakpoint,
  useTabletBreakpoint,
  useDesktopBreakpoint,
} from "helper/breakpoints";

const CookieConsentBar = () => {
  const { t, i18n } = useTranslation();

  const smartphone = useSmartphoneBreakpoint();
  const tablet = useTabletBreakpoint();
  const desktop = useDesktopBreakpoint();

  return (
    <CookieConsent
      debug={false}
      acceptOnScroll={true}
      acceptOnScrollPercentage={25}
      buttonText={t("cookieButtonText")}
      buttonStyle={{
        color: "#2d3748",
        fontWeight: "700",
      }}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        opacity: "80%",
      }}
    >
      <Typography
        variant="body2"
        textAlign={smartphone ? "center" : "left"}
        fontSize={desktop ? "1rem" : "0.7rem"}
        color="white"
      >
        {t("cookieConsentText")}
        <Link
          sx={{ color: "white", textDecoration: "underline" }}
          href="/cookies"
        >
          {t("cookiePolicy")}
        </Link>
      </Typography>
    </CookieConsent>
  );
};

export default CookieConsentBar;

import { Grid, List, ListItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import FooterListItem from "./FooterListItem";

interface Iprops {
  footerList: any; // Ts to do
}

const FooterList = ({ footerList }: Iprops) => {
  return (
    <>
      <Grid item md={4} xs={6}>
        <Box display="flex" justifyContent="center">
          <List sx={{ width: "144px" }}>
            <ListItem>
              <Typography color="white" variant="body1">
                {footerList.list_title}
              </Typography>
            </ListItem>
            {footerList.list_items.map((item: any) => (
              <FooterListItem
                key={item.list_item}
                listItem={item.list_item}
                isLink={item.isLink}
                listItemUrl={item.url}
              />
            ))}
          </List>
        </Box>
      </Grid>
    </>
  );
};

export default FooterList;

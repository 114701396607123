import { Container, Divider, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  useSmartphoneBreakpoint,
  useTabletBreakpoint,
  useDesktopBreakpoint,
} from 'helper/breakpoints';
import FooterList from './FooterList';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const smartphone = useSmartphoneBreakpoint();
  const tablet = useTabletBreakpoint();
  const desktop = useDesktopBreakpoint();
  const { t, i18n } = useTranslation();

  const footer_lists = [
    {
      list_title: 'Slyco.be',
      list_items: [
        {
          list_item: t('linkHome'),
          isLink: true,
          url: t('homeUrl'),
        },
        {
          list_item: t('linkServices'),
          isLink: true,
          url: t('servicesUrl'),
        },
        {
          list_item: t('linkContact'),
          isLink: true,
          url: t('contactUrl'),
        },
      ],
    },
    {
      list_title: 'Business',
      list_items: [
        {
          list_item: t('linkLegalNotice'),
          isLink: true,
          url: t('legalNoticeUrl'),
        },
        {
          list_item: t('linkPrivacyPolicy'),
          isLink: true,
          url: t('privacyPolicyUrl'),
        },
        {
          list_item: t('linkCookies'),
          isLink: true,
          url: t('cookiesUrl'),
        },
      ],
    },
    {
      list_title: 'Get in touch',
      list_items: [
        /* { list_item: "0477/77/77/77", isLink: false }, */
        {
          list_item: 'info@slyco.be',
          isLink: true,
          url: t('contactUrl'),
        },
      ],
    },
  ];

  return (
    <>
      <Box
        sx={{
          zIndex: '1100',
          width: '100%',
          backgroundColor: '#2d3748',
        }}
        paddingTop={4}
      >
        <Container>
          <Grid container>
            {footer_lists.map((footer_list) => (
              <FooterList
                key={footer_list.list_title}
                footerList={footer_list}
              />
            ))}
            {smartphone && (
              <Grid item xs={12}>
                <Divider color='lightgrey' />
              </Grid>
            )}
            <Grid
              item
              md={12}
              xs={12}
              display='flex'
              alignItems='center'
              flexDirection='column'
            >
              <Typography
                color='white'
                fontWeight='100'
                variant='body2'
                mt={2}
                mb={1}
              >
                Copyright © Slythesite 2020 - 2021
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Footer;

const genPathName = (currentPath: string, oldLang: string, newLang: string) => {
  if (oldLang === newLang) return currentPath;
  if (!currentPath.includes(oldLang)) {
    return `/${newLang}${currentPath}`;
  }
  if (newLang === "fr") {
    return currentPath.replace(`/${oldLang}`, "");
  }
  return currentPath.replace(oldLang, newLang);
};

export default genPathName;

import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Container,
  Drawer,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  Toolbar,
  Typography,
  useScrollTrigger,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import {
  useSmartphoneBreakpoint,
  useTabletBreakpoint,
  useDesktopBreakpoint,
} from 'helper/breakpoints';
import LanguageSelector from './LanguageSelector';

import slyLogo from 'assets/slyLogo.svg';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

interface IPropsElevationScroll {
  children: JSX.Element;
}

const Navbar = () => {
  const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  let colorText = '#2d3748';

  const colorBorder = '1px solid #2d3748';

  const smartphone = useSmartphoneBreakpoint();
  const tablet = useTabletBreakpoint();
  const desktop = useDesktopBreakpoint();

  const navLinks = [
    { text: t('linkHome'), url: t('homeUrl') },
    {
      text: t('linkServices'),
      url: t('servicesUrl'),
    },
    {
      text: t('linkContact'),
      url: t('contactUrl'),
    },
  ];
  const history = useHistory();

  const handleClick = () => {
    history.push(t('homeUrl'));
  };

  const logo = (
    <Box
      display='flex'
      justifyContent='center'
      component='img'
      alt='logoIcon'
      src={slyLogo}
      width='60px'
      mr={1}
      onClick={handleClick}
      sx={{ cursor: 'pointer' }}
    />
  );

  const handleDrawerToggle = () => {
    setDrawerIsOpen(!drawerIsOpen);
  };

  const ElevationScroll = ({ children }: IPropsElevationScroll) => {
    const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 0 });

    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
      sx: desktop
        ? trigger
          ? { backgroundColor: 'rgba(250, 250, 250, 1)' }
          : { backgroundColor: 'rgba(0, 0, 0, 0)' }
        : { backgroundColor: '#ffffff' },
    });
  };

  const drawer = (
    <Box
      width={smartphone ? '65vw' : tablet ? '35vw' : ''}
      height='100%'
      display='flex'
      flexDirection='column'
      alignItems='center'
    >
      <Box py={4}>{logo}</Box>
      <Box alignSelf='flex-start' flexGrow={1}>
        <List>
          {navLinks.map((link) => (
            <ListItem key={link.text}>
              <Link
                sx={{
                  textDecoration: 'none',
                  fontWeight: '500',
                  fontSize: '1rem',
                  lineHeight: '1.5',
                }}
                href={link.url}
                onClick={handleDrawerToggle}
              >
                {link.text}
              </Link>
            </ListItem>
          ))}
        </List>
      </Box>
      <Box alignSelf='flex-start' mb={2}>
        {/*         <Box display="flex" alignItems="center" pl={2}>
          <PhoneIcon sx={{ paddingRight: 1, fontSize: "30px" }} />
          <Typography
            variant="body1"
            sx={{ fontWeight: "500", fontSize: "1rem", lineHeight: "1.5" }}
          >
            0477/77.77.77
          </Typography>
        </Box> */}
        <Box display='flex' alignItems='center' pl={2}>
          <MailIcon sx={{ paddingRight: 1, fontSize: '30px' }} />
          <Typography
            variant='body1'
            sx={{ fontWeight: '500', fontSize: '1rem', lineHeight: '1.5' }}
          >
            info@slyco.be
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  const mobileNavbar = (
    <>
      <Grid container spacing={3}>
        <Grid item xs={3} display='flex' alignItems='center'>
          <IconButton
            aria-label='Boutton Menu Hamburger'
            size='large'
            edge='start'
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={6}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <Box display='flex' justifyContent='center' alignItems='center'>
            {logo}
          </Box>
        </Grid>
        <Grid
          item
          xs={3}
          display='flex'
          alignItems='center'
          justifyContent={tablet ? 'flex-end' : ''}
        >
          <LanguageSelector textColor='#2d3748' />
        </Grid>
      </Grid>
    </>
  );

  const desktopNavbar = (
    <>
      <Container>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <Box>{logo}</Box>
          </Grid>
          <Grid item md={6}>
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              width='100%'
              height='100%'
            >
              {navLinks.map((link) => (
                <Link
                  sx={{
                    color: colorText,
                    textDecoration: 'none',
                    '&:hover': { borderBottom: colorBorder },
                    fontWeight: '500',
                    fontSize: '1rem',
                    lineHeight: '1.5',
                  }}
                  key={link.text}
                  href={link.url}
                >
                  {link.text}
                </Link>
              ))}
              <LanguageSelector textColor={colorText} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );

  return (
    <>
      <ElevationScroll>
        <AppBar
          style={{
            transition:
              'background-color 400ms ease, box-shadow 400ms ease, color 400ms ease',
          }}
        >
          <Toolbar>
            {smartphone && mobileNavbar}
            {tablet && mobileNavbar}
            {desktop && desktopNavbar}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
      <Drawer
        variant='temporary'
        anchor={'left'}
        open={drawerIsOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
      >
        {drawer}
      </Drawer>
    </>
  );
};

export default Navbar;

import { Link, ListItem, Typography } from "@mui/material";

interface Iprops {
  isLink: boolean;
  listItem: any; // Ts to do
  listItemUrl: string;
}

const FooterListItem = ({ isLink, listItem, listItemUrl }: Iprops) => {
  return (
    <>
      <ListItem>
        {isLink ? (
          <Link sx={{ color: "white" }} href={listItemUrl}>
            <Typography color="white" variant="body2" fontWeight="200">
              {listItem}
            </Typography>
          </Link>
        ) : (
          <Typography color="white" variant="body2" fontWeight="200">
            {listItem}
          </Typography>
        )}
      </ListItem>
    </>
  );
};

export default FooterListItem;

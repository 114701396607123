import MenuItem from "@mui/material/MenuItem";
import { Box } from "@mui/system";
import { Typography, Popover } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import genPathName from "../../utils/genPathName";
import frIcon from "assets/frIcon.svg";
import nlIcon from "assets/nlIcon.svg";
import enIcon from "assets/enIcon.svg";

interface Iprops {
  textColor: string;
}

const LanguageSelector = ({ textColor }: Iprops) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t, i18n } = useTranslation();
  let lang = i18n.language;
  if (lang !== "fr" && lang !== "en" && lang !== "nl") {
    i18n.changeLanguage("fr");
    lang = "fr";
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelect = (newLang: string) => {
    const oldUrl = window.location.pathname;
    const newUrl = genPathName(oldUrl, lang, newLang);
    window.location.pathname = newUrl;
    i18n.changeLanguage(newLang);
    handleClose();
  };

  const french = lang === "fr" && (
    <Box display="flex" onClick={handleClick} px={2} sx={{ cursor: "pointer" }}>
      <Box display="flex" alignItems="center">
        <Box
          component="img"
          alt="french icon"
          src={frIcon}
          width="15px"
          height="15px"
          mr={1}
        />
      </Box>
      <Typography color={textColor} variant="body2">
        FR
      </Typography>
    </Box>
  );
  const english = lang === "en" && (
    <Box display="flex" onClick={handleClick} px={2} sx={{ cursor: "pointer" }}>
      <Box
        component="img"
        alt="enlgish icon"
        src={enIcon}
        width="15px"
        mr={1}
      />
      <Typography color={textColor} variant="body2">
        EN
      </Typography>
    </Box>
  );
  const dutch = lang === "nl" && (
    <Box display="flex" onClick={handleClick} px={2} sx={{ cursor: "pointer" }}>
      <Box
        component="img"
        alt="nederlands icon"
        src={nlIcon}
        width="15px"
        mr={1}
      />
      <Typography color={textColor} variant="body2">
        NL
      </Typography>
    </Box>
  );

  return (
    <Box>
      {french || english || dutch}
      <Popover
        sx={{ marginTop: 0.5 }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {lang !== "fr" && (
          <MenuItem onClick={() => onSelect("fr")}>
            <Box
              component="img"
              alt="french icon"
              src={frIcon}
              width="15px"
              mr={1}
            />
            <Typography variant="body2">FR</Typography>
          </MenuItem>
        )}
        {lang !== "en" && (
          <MenuItem onClick={() => onSelect("en")}>
            <Box
              component="img"
              alt="english icon"
              src={enIcon}
              width="15px"
              mr={1}
            />
            <Typography variant="body2">EN</Typography>
          </MenuItem>
        )}
        {lang !== "nl" && (
          <MenuItem onClick={() => onSelect("nl")}>
            <Box
              component="img"
              alt="nederlands icon"
              src={nlIcon}
              width="15px"
              mr={1}
            />
            <Typography variant="body2">NL</Typography>
          </MenuItem>
        )}
      </Popover>
    </Box>
  );
};

export default LanguageSelector;

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Suspense, lazy } from "react";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import CookieConsentBar from "./components/GDPR/CookieConsentBar";

const Home = lazy(() => import("./pages/Home"));
const Contact = lazy(() => import("./pages/Contact"));
const Error404 = lazy(() => import("./pages/Error404"));
const Services = lazy(() => import("./pages/Services"));
const LegalNotices = lazy(() => import("./pages/LegalNotices"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const Cookies = lazy(() => import("./pages/Cookies"));

export default function App() {
  return (
    <>
      <Router>
        <Suspense fallback="">
          <Navbar />
          <Switch>
            <Route
              path="/:lang(en|nl)?/privacy-policy"
              component={PrivacyPolicy}
            />
            <Route path="/:lang(en|nl)?/cookies" component={Cookies} />
            <Route
              path="/:lang(en|nl)?/legal-notices"
              component={LegalNotices}
            />
            <Route path="/:lang(en|nl)?/services" component={Services} />
            <Route path="/:lang(en|nl)?/contact" component={Contact} />
            <Route path="/:lang(en|nl)?/404" component={Error404} />
            <Route exact path="/:lang(en|nl)?" component={Home} />
            <Redirect from="/:lang(en|nl)?*" to="/:lang(en|nl)?/404" />
          </Switch>
          <CookieConsentBar />
          <Footer />
        </Suspense>
      </Router>
    </>
  );
}

import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#2d3748",
    },
    secondary: {
      main: "#2d3748",
    },
    error: {
      main: red.A400,
    },
    text: {
      primary: "#2d3748",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },

    fontFamily: ["Inter", "sans-serif"].join(","),
    // Big Title
    h2: {
      fontWeight: 700,
      fontSize: "3rem",
      color: "#2d3748",
    },
    // Medium title
    h3: {
      fontWeight: 700,
      fontSize: "2.5rem",
      color: "#2d3748",
    },
    h4: {
      fontWeight: 700,
      fontSize: "1.9rem",
      color: "#2d3748",
    },
    // Small title
    h5: {
      fontWeight: 700,
      fontSize: "1.2rem",
      color: "#2d3748",
    },
    //Medium text
    body1: {
      color: "#646e73",
      fontWeight: 400,
      fontSize: "1.1rem",
    },
    // Small text
    body2: {
      color: "#646e73",
    },
  },
});

export default theme;
